import React from "react";
import header_image from "../images/icon.jpg";

let descriptions_italics = {
    "Suswagatham ": "",
    "The Set Up": "",
    "Scene 1":
        "Soorya announces that Vijayanagara kingdom will not engage in any more conquests.",
    "Scene 2": "Soorya receives a message from his sister, Maya.",
    "Scene 3": "Maya reaches Vijayanagara and visits her family.",
    "Scene 4":
        "Keertana and Darshana grow up. Darshana is in love with Aditya, but Aditya loves Keertana.",
    "Scene 5":
        "An ailing Soorya declares Keertana as the future queen of Vijayanagar.",
    "Scene 6":
        "Nashooq instigates Maya against the sisters. Keertana confesses her love for Aditya.",
    "Scene 7": "Maya turns Darshana against Keertana.",
    "Scene 8":
        "Darshana confronts Keertana. Keertana and Aditya leave Vijayanagara for good.",
    "Scene 9": "Keertana and Aditya meet Veeru at Kumbasa.",
    "Scene 10":
        "Darshana regrets her aggressive behavior. Maya takes over the kingdom.",
    "Scene 11": "Vikram falls in love with Darshana.",
    "Scene 12":
        "Maya goes on war against other kingdoms, but leads a very lonely and unhappy life.",
    "Scene 13": "Keertana and Darshana prepare for war against Maya.",
    "Scene 14": "The war ends and the family unities.",
};

let descriptions = {
    "Suswagatham ": "",
    "The Set Up":
        "In the beautiful and mystical kingdom of Vijayanagara lives the kind and generous king, Sooryavamshi and his beautiful and ambitious sister, Mayamohini. The magical and powerful “mani” is the most valuable heirloom inherited by the ruler of this kingdom.",
    "Scene 1":
        "Soorya and his army celebrate their victory after the war. After witnessing all the destruction and lost lives, Soorya declares that he will become an ambassador of peace. He falls in love with Ambalika and gets married to her. Together, they have twin daughters.",
    "Scene 2":
        "Ambalika has passed. Keertana and Darshana are deeply attached to each other. While Keertana is kind and generous like her father, Darshana is feisty and ambitious like her aunt. Prabhu, the loyal guardian and the Soorya’s right-hand man, brings a message from Maya.",
    "Scene 3":
        "Maya is back home and is happy to reunite with her family. She expresses her disappointment in Soorya’s decision on no more conquests. But Soorya believes that a better future can be built through peace and kindness.",
    "Scene 4":
        "Darshana and Keertana grow up to be fine individuals. Their childhood companion, Aditya, is their best friend and biggest support. Darshana secretly loves Aditya, but he is in love with the quiet and mature Keertana. Aditya confesses his love to Keertana, but she only sees him as a friend.",
    "Scene 5":
        "Soorya is in poor health and is incapacitated. He believes that the people of Vijayanagara need a leader who is compassionate at heart and has their best interests in mind. He declares Keertana as the future queen of Vijayanagara and hands over the “mani” to her.",
    "Scene 6":
        "Nashooq instigates Maya to make a move against the sisters. Maya realizes that the only way to the throne is to turn the sisters against each other. Aditya is a great support to the sisters after their fathers’ demise. Keertana acknowledges her feelings towards Aditya and confesses her love.",
    "Scene 7":
        "Darshana sees Keertana and Aditya together and is heartbroken. Maya convinces Darshana that she has been cheated by Keertana. It is time for her to take some tough decisions in life.",
    "Scene 8":
        "Darshana accuses Keertana of being a manipulator and snatching the throne from her. She is heartbroken and sacrifices the throne and “mani” for her sister’s happiness. She and Aditya leave Vijayanagara for good.",
    "Scene 9":
        "Keertana and Aditya reach Kumbasa and meet Veeru. Aditya explains the situation and asks if they can stay there for a few days. Veeru wholeheartedly welcomes them.",
    "Scene 10":
        "Maya gets the “mani” from Darshana and declares herself the new Queen. Darshana realizes her mistake and seeks out Keertana. Nashooq confesses his love for Maya, but she rejects him.",
    "Scene 11":
        "Darshana comes to Kumbasa and begs Keertana and Aditya for their forgiveness. Veeru meets Darshana and falls in love with her at first sight.",
    "Scene 12":
        "Maya pursues her conquests, but she is still unhappy. Meanwhile, Keertana and Darshana get married to the love of their lives.",
    "Scene 13":
        "A distraught Prabhu reaches out to Keertana and Darshana and pleads them to return to Vijayanagara for the sake of their people. The sisters are reluctant about waging a war against their own aunt but decide they must do so to protect their land and their people.",
    "Scene 14":
        "Maya and Nashooq are taken down by Keertana and Darshana's army. Maya's greed and evilness is defected by her nieces’ selfless love. They convince her that true power lies in noble service, and peace for her people is her biggest victory. Maya promises to fulfill Soorya’s last wish and carry forward his legacy with her nieces by her side.",
};

let dance_info = {
    "Suswagatham ": [
        "Geetha Valiyil",
        "Sumitha Geboy, Anjali Sreehari, Saranya Rakesh, Jyoti Joson, Sreedevi Priyesh, Divya Devasia, Meera Cheviry, Nimmy Nygil",
    ],
    "The Victory Procession": [
        "Georgy Mattamana",
        "Georgy Mattamana, Sitara Chakkamadathil, Sanika Chakkamadathil, Rebecca Kadavil",
    ],
    "The Celebration": [
        "Minna Kuriakose",
        "Aswathy Praveen, Akshara Gireesh, Minakshi Nair, Devki Pullanchodan, Maya Sreedhar, Anika Menon, Esa Joseph, Nivedita Krishnan, Diya Nandakumar, Niketha Rajesh, Indira Priyaroopan",
    ],
    "The Journey Begins": [
        "Sushma Praveen ",
        "Sushma Praveen, Anju Joseph, Dileena Saju, Femin Charles, Jayasree Vattaparambil, Manju Nandakumar, Merin Mani, Neetu Simon, Radhita Radhakrishnan, Shalini Nambiar, Sheeja Sudheer, Smitha Tom, Soni Paull",
    ],
    "Maya - The Mystery Unfolds": [
        "Aparna Panicker",
        "Chetana Karnati, Deeksha Ramchandran, Deepa Sooraj, Jaanam Sumith, Manjusha Gireesh, Manisha Mohandoss, Mona Wadhwa, Saranya Sridharan, Shyama Gireesan, Sumati Patrick",
    ],
    "Friends Forever": [
        "Sangeetha Suresh and Ashwathi Menon",
        "Aaranyav Menon, Nikhil Thampi, Ashwika Sree, Aswanth Sree, Ananthajith Arunkumar, Namrata Shaju, Sanika Kumar, Suraj Sriram, Anamika Menon, Prateek Pillai, Ritika Menon, Riya Kodangil",
    ],
    "What The Heart Desires": [
        "Pearl Joby and Ziyan Ahmed",
        "Aeshan Somarajan, Sania Vijayakumar, Sagar Vijayakumar, Deepak Menon, Ziyan Ahmed, Pearl Joby, Aiswarya Suriaprakash, Serin Palathingal, Ajrin N, Venassa Jijo",
    ],
    "A New Era": [
        "Narmada Variyam",
        "Avantika G Kurup, Svana Kumar, Maria Joam, Devananda Deepak, Niveditha Arunkumar, Vedhika Menon, Sunidhi Sriram, Shreya Kodangil, Ritika Kodangil, Nandita Shaju",
    ],
    "Love Is In The Air": [
        "Deeksha Ram",
        "Rohini Raghu, Dhanya Jose, Kirthana Unnithan, Gauri Vellanoore, Niveditha Kattuparambil, Aathira Shahi, Anjali Mathew, Bhavni McNeace ",
    ],
    "The Rise of Maya": [
        "Suja Panicker Nair",
        "Savita Nair, Ahalya Srambical, Diya Sanooj, Nandini Vellanoore, Maithili Uniyal, Rishika Nambiar, Ashvitha Kidambi, Sanjana Maratt",
    ],
    "Captivating Kumbasa": [
        "Kavitha Ann James",
        "Jini Purakary, Sherly Vadakkoot, Merlin Thomas, Shirley T Pulickan, Sherin Sunny, Annie Sunny, Sarah Rainge, Nissy Varghese, Asha Kottackal, Sumi Jinson, Sneha Jobin, Ann Kavitha James",
    ],
    "Enigma ": [
        "Soumya Murali",
        "Karthika Bala Krishna, Smitha Vasudevan, Suja Panamana, Varsha Thalayattumpilly, Sreevidya Achuthan, Sreedevi Priyesh, Sunita Warrier, Soumya Muralidharan, Soumya Sandeep, Indu Sriram",
    ],
    "Enchanting Love": [
        "Ashwathi Menon",
        "Sneha Nandakumar, Ananya Ramasamy, Jia Patil, Jeasmitha Ramesh, Illakiya Viji, Vaneesha Vijay, Samprithi Muthuswamy, Nithya Vinod",
    ],
    "The Demon Inside": [
        "Mytreyi Nair",
        "Devanshi Pandya, Soujanya Kesiraju, Manasa Pandikunta, Pooja Sawlapurkar",
    ],
    "A Dream Wedding": [
        "Shyama Som Sunder and Jaanam Gopalakrishnan",
        "Esha T Maratt, Eshitha Ranjith, Fiona Ann Thomas, Jaanvi Karnati, Kavya Parekh, Keertana Manoj, Nandini Kossery, Nandita Maratt, Riddha Menon, Sanaya Langhnoja, Shraddha Sumith, Siona Liz Thomas, Smriti Menon, Trisha Somarajan",
    ],
    "The Revolution": [
        "Reem Varughese",
        "Nathan Abraham, Adarsh Rajesh, Sukrith Puthussery, Ivan Mathews, Alex Saju, Akhil Koranth, Roshan Alex, Nathan Xavier, Ben Thomas",
    ],
    "Happy Ending": ["Aparna Panicker", "all"],
};

let scene_to_dance = {
    "Suswagatham ": ["Suswagatham "],
    "The Set Up": ["The Victory Procession", "The Celebration"],
    "Scene 1": ["The Journey Begins"],
    "Scene 2": ["Maya - The Mystery Unfolds"],
    "Scene 3": ["Friends Forever"],
    "Scene 4": ["What The Heart Desires"],
    "Scene 5": ["A New Era"],
    "Scene 6": ["Love Is In The Air"],
    "Scene 7": [],
    "Scene 8": ["The Rise of Maya"],
    "Scene 9": ["Captivating Kumbasa"],
    "Scene 10": ["Enigma "],
    "Scene 11": ["Enchanting Love"],
    "Scene 12": ["The Demon Inside", "A Dream Wedding"],
    "Scene 13": ["The Revolution"],
    "Scene 14": ["Happy Ending"],
};

function get_description_heading(scene) {
    return (
        <p>
            <em>{descriptions_italics[scene]}</em>
        </p>
    );
}

function get_description(scene) {
    return <p>{descriptions[scene]}</p>;
}

function get_dances(scene) {
    if (scene === "Suswagatham ") {
        return (
            <div className="fs-5 dance">
                Choreographed by {dance_info[scene][0]}
                <p>Performed by {dance_info[scene][1]}</p>
            </div>
        );
    }
    let dance_list = scene_to_dance[scene];
    return (
        <p className="fs-5 dance">
            {dance_list.map((d, i) => {
                return (
                    <>
                        <em>
                            <b>{d}</b>
                        </em>
                        , Choreographed by {dance_info[d][0]}
                        <p>Performed by {dance_info[d][1]}</p>
                    </>
                );
            })}
        </p>
    );
}

function get_scene(scene) {
    return (
        <div className="container text-center my-3 py-3 fs-3">
            <h1>{scene}</h1>
            {get_description_heading(scene)}
            {get_description(scene)}
            {get_dances(scene)}
        </div>
    );
}
export default function Scenes({ eventTime }) {
    // Check if okay to display. Remove comment when needed when needed.
    // Start comment
    // let countDownDate = new Date(eventTime).getTime();
    // let now = new Date().getTime();
    // let distance = countDownDate - now;
    // if (distance > 0) {
    //     return (
    //         <div className="container text-center mt-3 fs-3">
    //             Content available after {eventTime}.
    //         </div>
    //     );
    // }
    // End comment
    return (
        <>
            <div className="container text-center">
                <img
                    src={header_image}
                    className="my-3 pt-3 w-25 h-25"
                    alt={"all characters background with info"}
                />
            </div>
            {get_scene("Suswagatham ")}
            {get_scene("The Set Up")}
            {Array(14)
                .fill(0)
                .map((_, i) => get_scene(`Scene ${i + 1}`))}
        </>
    );
}
