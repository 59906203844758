import "./App.css";
import Characters from "./components/Characters";
import NavbarComponent from "./components/NavbarComponent";
import Timer from "./components/Timer";
import Scenes from "./components/Scenes";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
    {
        element: (
            <NavbarComponent title="Home" nav1="Characters" nav2="Scenes" />
        ),
        children: [
            {
                path: "/",
                errorElement: (
                    <div> Error has occured while loading home page </div>
                ),
                element: <Timer eventTime="September 9, 2023 8:30:00 AM" />,
            },
            {
                path: "/Characters",
                errorElement: (
                    <div> Error has occured while loading characters page </div>
                ),
                element: <Characters />,
            },
            {
                path: "/Scenes",
                errorElement: (
                    <div> Error has occured while loading scenes page </div>
                ),
                element: <Scenes eventTime="September 9, 2023 11:30:00 AM" />,
            },
        ],
    },
]);

function App() {
    return (
        <>
            <RouterProvider router={router} />
        </>
    );
}

export default App;
