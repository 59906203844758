import React from "react";

// Importing images
import Jugnu from "../images/Jugnu.jpg";
import Sooryavamshi from "../images/Sooryavamshi.jpg";
import Mayamohini from "../images/Mayamohini.jpg";
import Keertana from "../images/Keertana.jpg";
import Darshana from "../images/Darshana.jpg";
import Aaditya from "../images/Aaditya.jpg";
import Viranjan from "../images/Viranjan.jpg";
import Prabhu from "../images/Prabhu.jpg";
import Nashooq from "../images/Nashooq.jpg";

let tagline = {
    Jugnu: "The Storyteller Genie",
    Sooryavamshi: "The Benevolent King",
    Mayamohini: "The Enigmatic Sister",
    Keertana: "The Magnanimous Princess",
    Darshana: "The Feisty Princess",
    Aaditya: "The Virtuous Prince",
    Viranjan: "The Charming Warrior",
    Prabhu: "The Loyal Guardian",
    Nashooq: "The Manipulative Sidekick",
};
let descriptions = {
    Jugnu: "He is a vivid storyteller and takes you through a journey of love, deceit, magic and mystery.",
    Sooryavamshi:
        "He is the king of Vijayanagara, loved and respected by all his people. There is a sincerity about him that is very alluring. He always makes decisions keeping the interests of his people at heart. For him, love is the most powerful force of all.",
    Mayamohini:
        "She is the embodiment of beauty and courage. She is ambitious and will stop at absolutely nothing to get what she wants. She is not shy to use her loved ones for her vested interests. She will fight until her last breath for what she feels is rightfully hers.",
    Keertana:
        "She is the epitome of kindness and charity, a true beauty with brains. She has a huge heart and lot of love to give. She is charming, confident and caring, and an ardent follower of her father’s principles.",
    Darshana:
        "She is fiercely independent and boldly outspoken; she never holds back when expressing herself. Her beauty is captivating, and she turns heads wherever she goes. She is deeply possessive of those she loves and is as ambitious as her beloved aunt.",
    Aaditya:
        "He is fearless and charismatic. When put to test, he never compromises on his principles. He has a strong personality, and he respects women who have a mind of their own. He falls deeply in love with his childhood companion, Keertana, who he feels complements him in every way.",
    Viranjan:
        "He is the man who lives life to the fullest. For him, every moment must be enjoyed like it was his last. He oozes charisma and wears his heart on his sleeves, never holding back on how he feels about anything. He falls in love with the feisty Darshana at first sight.",
    Prabhu: "He is the loyal and trusted advisor of King Suryavamshi. And he stands by his side through thick and think. When Vijayanagara is thrown in tumult, he worries for its people and supports the princesses to restore peace.",
    Nashooq:
        "He is the quintessential opportunist, ready to exploit a situation to his advantage. He serves at the behest of his love, Maya and together they plot to take control of Vijayanagara.",
};

function getCharacter(name, charImage) {
    return (
        <div className="container text-center my-3 py-3">
            <h1>{tagline[name]}</h1>
            <h1>{name}</h1>
            <img src={charImage} className="w-25 h-25" alt={name} />
            <section className="fs-3">{descriptions[name]}</section>
        </div>
    );
}
export default function Characters() {
    return (
        <div className="container">
            {getCharacter("Jugnu", Jugnu)}
            {getCharacter("Sooryavamshi", Sooryavamshi)}
            {getCharacter("Mayamohini", Mayamohini)}
            {getCharacter("Keertana", Keertana)}
            {getCharacter("Darshana", Darshana)}
            {getCharacter("Aaditya", Aaditya)}
            {getCharacter("Viranjan", Viranjan)}
            {getCharacter("Prabhu", Prabhu)}
            {getCharacter("Nashooq", Nashooq)}
        </div>
    );
}
